import styled, { keyframes } from "styled-components";
import { transparentize } from "polished";
import { Bonus, Currency, formatAmount } from "models";
import { routes } from "routes";
import { fiatToCrypto } from "lib/order-conversion";
import { render } from "lib/render";
import { elementFont, textWidth } from "lib/text-width";
import { hintSkeleton } from "styled-mixins/skeleton";
import { replicate } from "lib/replicate";
import { reroute } from "./reroute";

import { useNavigate } from "hooks/use-navigate";
import { usePromise } from "hooks/use-promise";
import { BuyOrder } from "shared/models/buy";
import { ApiError, ApiService, isCustomException, useApiService } from "services/api";
import { useState } from "hooks/use-state";
import { useTelegramBackButton, useTelegramMainButton } from "services/telegram";
import { MutableRefObject, useEffect, useLayoutEffect, useRef } from "react";
import { NotificationsService, useNotificationsService } from "services/notifications";
import { Translation, useTranslation } from "services/i18n";
import { useTrack } from "services/track";
import { useBalance } from "../../services/balance";

import { CentralHeader } from "components/central-header";
import { BuyBankPageSkeleton } from "./bank";
import { useOrderCurrencies, useOrderCurrency } from "../../services/currencies";
import { useOrderService } from "services/order";

const validOrderStatuses = ["enter_amount"] as const satisfies readonly BuyOrder["status"][];

export function validateOrderCreationError(
  currencies: Currency[],
  error: Error,
  notifications: NotificationsService,
  api: ApiService,
  t: Translation,
) {
  const isInvalidCurrency = error instanceof ApiError && error.response?.status === 409;

  if (isInvalidCurrency) {
    error.response?.json().then(response => {
      const payload: { currency: string; currencies: string[] } = response.payload;
      notifications.notify({
        title: t("ui", "error_currency_not_supported_title", {
          currency: payload.currency,
        }),
        message: t("ui", "error_currency_not_supported_message"),
        buttons: payload.currencies
          .filter(c => currencies.some(_ => _.code === c))
          .map((currency: string) => ({
            label: currency,
            onClick() {
              void api.setActiveCurrency({ primary: currency });
            },
          })),
      });
    });
  }

  if (!isCustomException(error)) {
    notifications.notify({
      message: t("errors", "wallet_order_error"),
    });
  }
}

export function BuyAmountPage() {
  const t = useTranslation();
  const track = useTrack();
  const api = useApiService();
  const navigate = useNavigate();
  const notifications = useNotificationsService();
  const balance = useBalance();
  const currencies = useOrderCurrencies();
  const orderService = useOrderService();

  const submitting = useState(false);
  const valueFrom = useState(0);

  const order = usePromise(
    () =>
      api.createBuyOrder().then(order => {
        orderService.setNeedRecaptcha(order.needRecaptcha);
        return order;
      }),
    [],
  );
  useEffect(() => {
    if (order.value && order.value.status !== "enter_amount") {
      navigate(reroute(order.value));
      return;
    }

    if (order.error) {
      validateOrderCreationError(currencies, order.error, notifications, api, t);
      navigate(routes.main());
      return;
    }

    if (order.value && order.value.minAmount >= order.value.maxAmount) {
      notifications.notify({
        message: "Method is currently unavailable, please try again later",
      });
      navigate(routes.main());
      return;
    }
  }, [order]);

  const valueTo = order.value ? fiatToCrypto(order.value, valueFrom.get) : 0;

  const presets = useOrderCurrency(order.value?.currencyFrom?.code)?.valuePresets;

  const canSubmit =
    !submitting.get &&
    order.value &&
    valueFrom.get >= order.value.minAmount &&
    valueFrom.get <= order.value.maxAmount;

  async function handleSubmit() {
    if (!canSubmit) return;

    try {
      submitting.set(true);
      const newOrder = await api.setBuyOrderAmount(
        order.value.id,
        valueFrom.get,
        window.location.origin + routes.buy.status(order.value),
      );
      track("[Wallet] Buy: amount provided", {
        currency: order.value.currencyFrom.code,
        fiat: valueFrom.get,
        jetton: valueTo,
      });

      navigate(reroute(newOrder));
    } catch (err) {
      notifications.notify({
        message: t("errors", "wallet_order_error"),
      });
    } finally {
      submitting.set(false);
    }
  }

  useTelegramBackButton({
    text: t("ui", "wallet_back_button_currency_selection"),
    onClick() {
      track("[Wallet] back to main page");
      navigate(routes.main());
    },
  });

  useTelegramMainButton({
    text: order.value
      ? t("ui", "wallet_buy_deposit_on", {
          amount: formatAmount(valueFrom.get, order.value.currencyFrom, true),
        })
      : "...",
    shown: canSubmit,
    progress: submitting.get,
    onClick: handleSubmit,
  });

  if (submitting.get) {
    return <BuyBankPageSkeleton />;
  }

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        event.stopPropagation();

        handleSubmit();
      }}
    >
      <BuyAmountView
        bonuses={balance?.availableBonuses}
        valueFrom={valueFrom.get}
        onFromChange={valueFrom.set}
        valueTo={valueTo}
        presets={presets}
        order={order.value}
      />
    </form>
  );
}

type Props = {
  valueFrom: number;
  valueTo: number;
  onFromChange(value: number): void;

  bonuses: Bonus[] | undefined;
  presets: number[] | undefined;
  order: (BuyOrder & { status: (typeof validOrderStatuses)[number] }) | undefined;

  // для того чтобы можно было извне сказать, что воспринимайте инпут грязным
  dirtySignal?: MutableRefObject<undefined | (() => void)>;
};

export function BuyAmountView({ order, ...props }: Props) {
  const t = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);
  const inputDirty = useState(false);
  const inputShake = useState(false);
  const isLoading = useState(true);
  const startTime = useState(0);

  useEffect(() => {
    if (!order) {
      startTime.set(Date.now());
    }
  }, []);

  useEffect(() => {
    if (order) {
      const loadingTime = Date.now() - startTime?.get;
      if (loadingTime < 2000) {
        const timeout = setTimeout(() => {
          isLoading.set(false);
        }, 1000);

        return () => {
          clearTimeout(timeout);
        };
      } else {
        isLoading.set(false);
      }
    }
  }, [order]);

  useEffect(() => {
    if (props.dirtySignal) {
      let timeout: NodeJS.Timeout;
      props.dirtySignal.current = () => {
        inputDirty.set(true);
        clearTimeout(timeout);
        inputShake.set(true);
        timeout = setTimeout(() => {
          inputShake.set(false);
          clearTimeout(timeout);
        }, 300);
      };
    }
  }, [props.dirtySignal]);

  const availableBonuses =
    props.bonuses?.filter(
      bonus =>
        bonus.isCurrent &&
        props.valueFrom >= (bonus.minAmount ?? Number.MIN_VALUE) &&
        props.valueFrom < (bonus.maxAmount ?? Number.MAX_VALUE),
    ) ?? [];

  const bonusValue = availableBonuses
    .map(bonus => bonus.amountMultiplier * props.valueFrom)
    .reduce((a, b) => a + b, 0);

  const freespins = availableBonuses
    .map(bonus => bonus.freespins)
    .filter((x: number | null): x is number => x !== null)
    .reduce((a, b) => a + b, 0);

  function relayout() {
    if (inputRef.current) {
      const text = (inputRef.current.value || 0).toString();
      inputRef.current.style.maxWidth = textWidth(text, elementFont(inputRef.current)) + "px";
    }
  }

  useEffect(() => {
    document.fonts.ready.then(relayout);
  }, [inputRef.current]);

  useLayoutEffect(relayout, [props.valueFrom, inputRef.current, order?.currencyFrom.displayFormat]);

  const inputShouldBeSmall = props.valueFrom.toString().length > 6;

  return (
    <Container>
      <CentralHeader>
        {props.bonuses === undefined ? (
          <TextSkeleton as={"div"}>Enter deposit amount:</TextSkeleton>
        ) : props.bonuses.length === 0 ? (
          t("ui", "wallet_buy_deposit_amount")
        ) : (
          t("ui", "wallet_buy_deposit_amount_bonus")
        )}
      </CentralHeader>
      {order ? (
        <InputContainer $shake={inputShake.get}>
          <Input
            $small={inputShouldBeSmall}
            ref={inputRef}
            inputMode="numeric"
            placeholder="0"
            value={props.valueFrom === 0 ? "" : props.valueFrom}
            onChange={event => {
              inputDirty.set(true);
              const value = parseInt(event.currentTarget.value);

              if (Number.isNaN(value)) {
                props.onFromChange(0);
              } else if (event.currentTarget.value.length <= 7) {
                props.onFromChange(value);
              }
            }}
          />
          <InputCurrency $small={inputShouldBeSmall}>
            {render(order.currencyFrom.displayFormat, { amount: "" })}
          </InputCurrency>
          {(inputDirty.get || props.valueFrom !== 0) && (
            <InputError>
              {props.valueFrom < order.minAmount ? (
                <>
                  {t("ui", "wallet_input_min")}{" "}
                  {formatAmount(order.minAmount, order.currencyFrom, true)}
                </>
              ) : props.valueFrom > order.maxAmount ? (
                <>
                  {t("ui", "wallet_input_max")}{" "}
                  {formatAmount(order.maxAmount, order.currencyFrom, true)}
                </>
              ) : undefined}
            </InputError>
          )}
        </InputContainer>
      ) : (
        <InputContainer>
          <InputSkeleton />
        </InputContainer>
      )}
      {(bonusValue > 0 || freespins > 0 || props.valueFrom > 0) && (
        <Output>
          <OutputLabel>{t("ui", "wallet_buy_will_receive")}</OutputLabel>

          {props.valueFrom > 0 && (
            <OutputBalance>
              {order && formatAmount(props.valueFrom, order.currencyFrom)}
            </OutputBalance>
          )}

          {order && bonusValue > 0 && (
            <OutputBonusBalance>
              {t("ui", "bonus")} {order && formatAmount(bonusValue, order.currencyFrom)}
            </OutputBonusBalance>
          )}

          {order && freespins > 0 && (
            <OutputBonusFreespins>
              + {freespins} {t("ui", "freespins")}
            </OutputBonusFreespins>
          )}
        </Output>
      )}
      {props.presets === undefined || order === undefined || isLoading.get ? (
        <QuickAmounts>
          {replicate(6).map(i => (
            <QuickAmount key={i}>
              <QuickAmountFiat>
                <TextSkeleton>1000 XX</TextSkeleton>
              </QuickAmountFiat>
            </QuickAmount>
          ))}
        </QuickAmounts>
      ) : (
        <QuickAmounts>
          {props.presets.map(Number).map((amount, i) => (
            <QuickAmount
              key={amount}
              $selected={amount === props.valueFrom}
              type="button"
              onClick={() => props.onFromChange(amount)}
            >
              {i === 2 && (
                <QuickAmountPopular>{t("ui", "wallet_buy_deposit_popular")}</QuickAmountPopular>
              )}
              <QuickAmountFiat $selected={amount === props.valueFrom}>
                {amount} <span>{render(order.currencyFrom.displayFormat, { amount: "" })}</span>
              </QuickAmountFiat>
            </QuickAmount>
          ))}
        </QuickAmounts>
      )}
    </Container>
  );
}

export function BuyAmountPageSkeleton() {
  return (
    <BuyAmountView
      valueFrom={0}
      valueTo={0}
      onFromChange={() => {}}
      bonuses={undefined}
      presets={undefined}
      order={undefined}
    />
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InputContainer = styled.label<{ $shake?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;

  animation: infinite 200ms ${props => props.$shake && shakeAnimation};
`;

const Input = styled.input<{ $small?: boolean }>`
  font-size: ${props => (props.$small ? 3 : 4)}rem;
  font-weight: 800;
  line-height: 4rem;
  border: 0;
  box-shadow: none;
  background: none;
  margin: 0;
  padding: 0;
  font-family: "Nunito", "Helvetica Neue", serif;

  &:focus {
    outline: 0;
    box-shadow: none;
  }

  color: ${props => props.theme.text};
`;

const InputCurrency = styled.div<{ $small?: boolean }>`
  margin-left: 8px;
  color: ${props => props.theme.hint};
  font-family: "Nunito", "Helvetica Neue", serif;
  font-size: ${props => (props.$small ? 3 : 4)}rem;
  font-weight: 800;
  line-height: 4rem;
  letter-spacing: -2.56px;
`;

const InputError = styled.div`
  position: absolute;
  bottom: calc(100% + 5px);

  color: ${props => props.theme.error};
  font-size: 0.8125rem;
  line-height: 1.125rem;
  padding: 0 4px;
`;

const Output = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto 16px;
  gap: 8px;
  width: fit-content;
`;

const OutputLabel = styled.div`
  color: ${props => props.theme.hint};

  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 140%;

  margin-bottom: 4px;
`;

const OutputBonus = styled.div`
  border-radius: 999px;
  padding: 6px 20px;
  text-align: center;

  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  line-height: 140%;
`;

const OutputBalance = styled(OutputBonus)`
  border: 1px solid #29356f;
`;

const OutputBonusBalance = styled(OutputBonus)`
  padding: 7px 13px;
  font-weight: 600;
  background: linear-gradient(
    92deg,
    #6b40ea 0%,
    #6558ee 19.92%,
    #5c6cf3 39.84%,
    #4f7ff7 59.76%,
    #3a91fb 79.68%,
    #00a3ff 99.6%
  );
`;

const OutputBonusFreespins = styled(OutputBonus)`
  border: 1px solid #6b40ea;

  background: rgba(50, 60, 119, 0.6);

  box-shadow:
    12px 6px 24px -14px rgba(0, 163, 255, 0.6),
    -12px 6px 24px -14px rgba(191, 69, 248, 0.6);
`;

const QuickAmounts = styled.div`
  margin: 12px -4px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4px;
`;

const QuickAmount = styled.button<{ $selected?: boolean }>`
  position: relative;

  border: 0;
  margin: 0;
  background: none;
  cursor: pointer;

  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;

  padding: 12px;
  border-radius: 20px;
  background: ${props =>
    props.$selected ? props.theme.button : transparentize(0.9, props.theme.button)};

  backdrop-filter: blur(8px);
`;

const QuickAmountPopular = styled.div`
  position: absolute;
  top: -13px;
  padding: 4px 7px;
  background: ${props => props.theme.button};
  color: ${props => props.theme.buttonText};

  font-size: 0.625rem;
  font-weight: 500;
  line-height: 0.625rem;
  text-transform: uppercase;
  border-radius: 36px;
  border: 2px solid ${props => props.theme.bg};
`;

const QuickAmountFiat = styled.div<{ $selected?: boolean }>`
  color: ${props => (props.$selected ? props.theme.buttonText : props.theme.text)};

  font-family: "Nunito", "Helvetica Neue", serif;
  font-size: 1rem;
  font-weight: 800;
  line-height: 1.5rem;

  span {
    color: ${props => (props.$selected ? props.theme.buttonText : props.theme.hint)};
  }
`;

const TextSkeleton = styled.span`
  ${hintSkeleton};
`;

const InputSkeleton = styled.div`
  ${hintSkeleton};
  height: 100%;
  width: 100px;
`;

export const shakeAnimation = keyframes`
  0% { transform: translateX(0) }
  25% { transform: translateX(5px) }
  50% { transform: translateX(-5px) }
  75% { transform: translateX(5px) }
  100% { transform: translateX(0) }
`;
