import { Atom } from "../lib/event-bus";
import { createContext, useContext } from "react";

export class OrderService extends Error {
  public needRecaptcha = new Atom<boolean | null>(null);

  constructor() {
    super();
  }

  public setNeedRecaptcha(needRecaptcha: boolean | null): void {
    this.needRecaptcha.set(needRecaptcha);
  }
}

export const OrderServiceContext = createContext<null | OrderService>(null);

export function useOrderService() {
  const service = useContext(OrderServiceContext);
  if (service === null) throw new Error("Wrap with OrderServiceContext.Provider");
  return service;
}
