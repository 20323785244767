import { useCallback, useEffect, useState } from "react";

declare global {
  interface Window {
    grecaptcha: {
      enterprise: {
        ready: (callback: () => void) => void;
        execute: (siteKey: string, options: { action: string }) => Promise<string>;
      };
    };
  }
}

export enum ReCaptchaActionType {
  Signup = "signup",
  login = "login",
  password_reset = "password_reset",
  get_price = "get_price",
  cart_add = "cart_add",
  cart_view = "cart_view",
  payment_add = "payment_add",
  checkout = "checkout",
  transaction_confirmed = "transaction_confirmed",
  play_song = "play_song",
}
  
export function useEnterpriceRecaptcha() {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    const loadRecaptchaScript = () => {
      if (!window.grecaptcha) {
        const script = document.createElement("script");
        script.src = `https://www.google.com/recaptcha/enterprise.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`;
        script.async = true;
        script.onload = () => setIsScriptLoaded(true);
        document.body.appendChild(script);
      } else {
        setIsScriptLoaded(true);
      }
    };

    loadRecaptchaScript();
  }, []);

  const executeRecaptcha = useCallback(
    (action: ReCaptchaActionType): Promise<string | null> => {
      return new Promise(resolve => {
        if (window.grecaptcha && isScriptLoaded) {
          window.grecaptcha.enterprise.ready(() => {
            window.grecaptcha.enterprise
              .execute(String(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY), { action })
              .then(token => resolve(token))
              .catch(() => resolve(null));
          });
        } else {
          resolve(null);
        }
      });
    },
    [isScriptLoaded],
  );

  return { executeRecaptcha, isScriptLoaded };
}
