import "./index.css";

import React from "react";
import ReactDOM from "react-dom/client";

import { BalanceService } from "services/balance";
import { ApiService, InternalServerError, UnauthorizedError } from "services/api";
import { I18nService, useTranslation, I18nContext } from "services/i18n";
import { TelegramService, TelegramServiceContext } from "services/telegram";
import { TransactionsService, TransactionsServiceContext } from "services/transactions";
import { ConfigService, ConfigServiceContext } from "services/config";
import { NotificationsService, NotificationsServiceContext } from "services/notifications";
import { ApiServiceContext } from "services/api";
import { BalanceServiceContext } from "services/balance";
import { TrackService, TrackServiceContext } from "services/track";

import { App } from "./app";
import { ErrorPage } from "./pages/error";
import { getWebApp } from "services/webapp";
import { flags } from "lib/flags";
import { isNotUndefined } from "lib/is-not-undefined";
import { CurrenciesService, CurrenciesServiceContext } from "./services/currencies";
import { OrderService, OrderServiceContext } from "services/order";

class NoQueryParamError extends Error {
  name = "NoQueryParamError";
  constructor(public param: string) {
    super(`Provide '${param}' query parameter`);
  }
}

class NoEnvironmentError extends Error {
  name = "NoEnvironmentError";
  constructor(public variable: string) {
    super(`Provide ${variable} environment variable`);
  }
}

function query(param: string): string;
function query<T>(param: string, def?: T): string | T;
function query<T>(param: string, def?: T): string | T {
  const value = new URLSearchParams(window.location.search).get(param);

  if (value === null) {
    if (def === undefined) {
      throw new NoQueryParamError(param);
    } else {
      return def;
    }
  } else {
    return value;
  }
}

const publicEnv = {
  API: process.env.REACT_APP_API,
  API_WS: process.env.REACT_APP_API_WS,
  DEPOSIT_TONKEEPER: process.env.REACT_APP_DEPOSIT_TONKEEPER,
  DEPOSIT_TONHUB: process.env.REACT_APP_DEPOSIT_TONHUB,
};

function env(variable: keyof typeof publicEnv): string {
  const value = publicEnv[variable];
  if (!value) throw new NoEnvironmentError(variable);
  return value;
}

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement as HTMLElement);

function mount(element: React.ReactElement) {
  root.render(<React.StrictMode>{element}</React.StrictMode>);
}

type TelegramUserID = string;
async function checkToken(token: string): Promise<false | TelegramUserID> {
  const options = { headers: { Authorization: token } };
  const response = await fetch(env("API") + "/me", options);

  if (response.ok) {
    const user = await response.json();
    return (user.userId ?? user.globalId).toString();
  } else {
    return false;
  }
}

function deleteQueryParams(keys: string[]) {
  const searchParams = new URLSearchParams(window.location.search);

  for (const key of keys) {
    searchParams.delete(key);
  }

  const newURL =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    (searchParams.toString().length > 0 ? "?" + searchParams.toString() : "");

  window.history.pushState({ path: newURL }, "", newURL);
}

function unstore(key: string) {
  try {
    window.sessionStorage.removeItem(key);
  } catch {}
}

function store(key: string, value: string) {
  try {
    window.sessionStorage.setItem(key, value);
  } catch {}
}

function load(key: string): string | null {
  try {
    return window.sessionStorage.getItem(key);
  } catch {
    return null;
  }
}

async function getToken(): Promise<string> {
  const storedToken = load("token");
  const realToken = query("real_token", null);
  const initData = window.Telegram?.WebApp?.initData;

  deleteQueryParams(["token", "real_token"]);

  if (realToken) {
    if (!checkToken(realToken)) {
      throw new NoQueryParamError("token");
    }

    store("token", realToken);
    return realToken;
  }

  if (process.env.REACT_APP_DEV_TOKEN) {
    return process.env.REACT_APP_DEV_TOKEN;
  }

  if (initData) {
    const response = await fetch(env("API") + "/auth/token", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ webapp: initData }),
    });

    if (!response.ok) {
      throw new NoQueryParamError("token");
    }

    const newToken = await response.json();

    if (!(await checkToken(newToken))) {
      unstore("token");
      throw new NoQueryParamError("token");
    }

    store("token", newToken);
    return newToken;
  }

  if (storedToken && (await checkToken(storedToken))) {
    return storedToken;
  }

  unstore("token");
  throw new NoQueryParamError("token");
}

async function initializeServices() {
  const webapp = getWebApp();
  const token = await getToken();

  const config = new ConfigService(
    {
      API: env("API"),
      API_WS: env("API_WS"),
      DEPOSIT_TONKEEPER: env("DEPOSIT_TONKEEPER"),
      DEPOSIT_TONHUB: env("DEPOSIT_TONHUB"),
    },
    {
      token,
      tonConnect: query("ton_connect", false),
      tgp: query("tgp", "0") === "1",
    },
  );

  const notification = new NotificationsService();
  const i18n = new I18nService();
  const api = new ApiService(env("API"), token, i18n, notification);
  const balance = new BalanceService(env("API_WS"), token);
  const transactions = new TransactionsService(env("API_WS"), token);
  const telegram = new TelegramService(webapp);
  const track = new TrackService();
  const currencies = new CurrenciesService(api);
  const order = new OrderService();

  await Promise.all([i18n.connect(api), currencies.mount()]);

  void telegram.connect();
  void balance.connect();
  void transactions.connect(api);
  void track.mount(token);

  telegram.expand();
  telegram.ready();

  return { config, api, balance, transactions, i18n, telegram, notification, track, currencies, order };
}

function Providers(props: {
  providers: (undefined | ((children: JSX.Element) => JSX.Element))[];
  children: JSX.Element;
}) {
  return props.providers
    .filter(isNotUndefined)
    .reduceRight((result, provider) => provider(result), props.children);
}

async function main() {
  const services = await initializeServices();

  services.track.track("[Wallet] open");

  mount(
    <Providers
      providers={[
        _ => <NotificationsServiceContext.Provider value={services.notification} children={_} />,
        _ => <ConfigServiceContext.Provider value={services.config} children={_} />,
        _ => <ApiServiceContext.Provider value={services.api} children={_} />,
        _ => <BalanceServiceContext.Provider value={services.balance} children={_} />,
        _ => <TransactionsServiceContext.Provider value={services.transactions} children={_} />,
        _ => <TelegramServiceContext.Provider value={services.telegram} children={_} />,
        _ => <I18nContext.Provider value={services.i18n} children={_} />,
        _ => <TrackServiceContext.Provider value={services.track} children={_} />,
        _ => <CurrenciesServiceContext.Provider value={services.currencies} children={_} />,
        _ => <OrderServiceContext.Provider value={services.order} children={_} />,
      ]}
    >
      <App />
    </Providers>,
  );
}

function ErrorPageWrapper({ error }: { error: Error }) {
  const t = useTranslation();

  if (error instanceof NoQueryParamError) {
    return (
      <ErrorPage>
        <p>{t("errors", "not_browser_webapp_line_1")}</p>
        <small>{t("errors", "not_browser_webapp_line_2")}</small>
      </ErrorPage>
    );
  }

  if (error instanceof NoEnvironmentError) {
    return (
      <ErrorPage>
        <p>{t("errors", "not_configured")}</p>
        <code>{error.variable}</code>
      </ErrorPage>
    );
  }

  if (error instanceof UnauthorizedError) {
    return (
      <ErrorPage>
        <p>{t("errors", `error_401_unauthorized_${error.reason}_1`)}</p>
        <small>{t("errors", `error_401_unauthorized_${error.reason}_2`)}</small>
      </ErrorPage>
    );
  }

  if (error instanceof InternalServerError) {
    return (
      <ErrorPage>
        <p>{t("errors", "something_wrong")}</p>
      </ErrorPage>
    );
  }

  return (
    <ErrorPage>
      <p>{t("errors", "something_wrong")}</p>
      <code>{JSON.stringify(error)}</code>
    </ErrorPage>
  );
}

function errorHandler(error: Error) {
  console.error(error);
  return mount(
    <I18nContext.Provider value={new I18nService()}>
      <ErrorPageWrapper error={error} />
    </I18nContext.Provider>,
  );
}

window.addEventListener('unhandledrejection', (event) => {
  console.error('Unhandled promise rejection:', event.reason);
  console.error('Unhandled promise rejection:', event);
});

window.addEventListener("error", event => {
  console.log(event, 'EVENT_ERROR');
  errorHandler(event.error)
});

window.addEventListener("api-error", event => errorHandler(event.error));

if (flags.ios && flags.iframe) {
  const blurAllInputs = () => {
    document.querySelectorAll("input").forEach(input => {
      input.blur();
    });
  };

  window.addEventListener("message", event => {
    if (event.data === "input.blur") blurAllInputs();
  });

  document.addEventListener("scroll", blurAllInputs);
}

void main();
